<template>
  <div>
    <v-row justify="center">
      <v-col cols="12" class="pt-16">
        <v-container>
          <back-button @click.native="$router.go(-1)"></back-button>
          <v-row>
            <v-col cols="12" lg="6">
              <h3 class="mb-8 home-header-3">
                Which of the following vaccinations are you booking?
              </h3>
              <v-radio-group
                v-model="selectedBookingTypesGroup"
                v-on:change="onRadioGroupClicked"
                class="mb-4"
              >
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesGroup = COVID_FLU_CODE;
                    onRadioGroupClicked();
                  "
                  label="COVID-19, Flu or both"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=COVID_FLU_CODE
                  :ripple="false"
                ></v-radio>
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesGroup = PERTUSSIS_CODE;
                    onRadioGroupClicked();
                  "
                  label="Pertussis"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=PERTUSSIS_CODE
                  :ripple="false"
                ></v-radio>
                <v-radio
                  v-on:keyup.native.enter="
                    selectedBookingTypesGroup = RSV_CODE;
                    onRadioGroupClicked();
                  "
                  label="RSV"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=RSV_CODE
                  :ripple="false"
                ></v-radio>
                <!-- disabled per https://aptvision.atlassian.net/browse/RIS-8946 -->
                <v-radio
                  v-if="false"
                  v-on:keyup.native.enter="
                    selectedBookingTypesGroup = MMR_CODE;
                    onRadioGroupClicked();
                  "
                  label="MMR"
                  color="#006078"
                  class="ml-4 px-8 py-4 black--text"
                  :value=MMR_CODE
                  :ripple="false"
                ></v-radio>
                <!-- END -->
              </v-radio-group>
              <v-btn
                @click="onAfterContinueClicked"
                class="v-btn--primary"
                :disabled="vaccineNotSelected"
              >
                Continue
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex'
import BackButton from '@/components/BackButton'

const codes = {
  COVID_FLU_CODE: 'COVID_FLU',
  MMR_CODE: 'MMR',
  PERTUSSIS_CODE: 'PERTUSSIS',
  RSV_CODE: 'RSV'
}

export default Vue.extend({
  components: {
    BackButton
  },
  name: 'StepBookingTypesGroups',
  data: () => ({
    selectedBookingTypesGroup: '',
    ...codes
  }),
  computed: {
    ...mapGetters([
    'getAge'
    ]),
    vaccineNotSelected() {
      return !this.selectedBookingTypesGroup
    }
  },
  methods: {
    onRadioGroupClicked() {
      this.$store.commit(
        'setSelectedBookingTypesGroup',
        this.selectedBookingTypesGroup
      );
    },
    onAfterContinueClicked() {
      if(this.selectedBookingTypesGroup === this.PERTUSSIS_CODE) {
        this.$gtag.event('Pertussis selected')
        this.$router.push('/booking/pertussis-pregnancy-1').catch(() => {})
      } else if(this.selectedBookingTypesGroup === this.RSV_CODE) {
        this.$gtag.event('RSV selected')
        this.$router.push('/booking/rsv-pregnancy-1').catch(() => {})
      } else {
        if (this.selectedBookingTypesGroup === this.COVID_FLU_CODE) {
        this.$gtag.event('Covid flow selected')
        if (this.getAge < 5) {
          this.$router.push('/booking/not-eligible-age').catch(() => {});
        }
        else if (this.getAge >= 5 && this.getAge < 12) {
          this.$router.push('/booking/behalf-of-child').catch(() => {});
        }
        else {
          this.$router.push('/booking/booking-types-1').catch(() => {});
        }
       }
       else {
        this.$gtag.event('MMR Flow Selected')
        if (this.getAge >= 6 && this.getAge < 26) {
          this.$router.push('/booking/pregnancy').catch(() => {});
        }
        else {
          this.$router.push('/booking/booking-types-groups-eligibility').catch(() => {});
        }
       }
      }
    }
  }
});
</script>
